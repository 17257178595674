import React from "react";

import "bootstrap/dist/css/bootstrap.min.css"
import { Row } from 'react-bootstrap';
import '../../sass/app.scss';
import Image from '../Images'
import { Link } from "gatsby";
import Slider from "react-slick";
import arrowLeft from '../../images/sobrenos/arrowLeft.png';
import arrowRigth from '../../images/sobrenos/arrowRigth.png';
import Video from '../../components/homepage/video'



function CustomRigthArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className + " m-auto arrows"}
            style={{ ...style, display: "table-cell", width: "25%", textAlign: "center" }}
            onClick={onClick} aria-hidden="true"
        > <img src={arrowRigth} loading="lazy" alt="arrow rigth" /> </div>
    );
}

function CustomLeftArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className + " m-auto arrows"}
            style={{ ...style, display: "table-cell", width: "25%", textAlign: "center" }}
            onClick={onClick} aria-hidden="true"
        > <img src={arrowLeft} loading="lazy" alt="arrow left" /> </div>
    );
}


const IntroHome = ({ data, xl, videos }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        nextArrow: <CustomRigthArrow />,
        prevArrow: <CustomLeftArrow />,
        centerMode: false
    };

    return (
        <div className={videos ? "container-devices margin-sections height-10vh" : "container-devices margin-sections height-10vh"}
        style={{ paddingbottom: videos ? '0 !important' : '10vh'}}>
            <Slider {...settings}>
                {videos ?
                    data.video.map((data, i) => (
                        <div className='slider-video'>
                            <Video data={data} />
                        </div>
                    ))
                    :
                    data.home.map((data, i) => (
                        <Link to={data.href}>
                            <Row>
                                <Image src={data.img} alt={data.alt} className="banner-intro-home max-width" />
                            </Row>
                        </Link>
                    ))

                }

            </Slider>
        </div >
    )
}

export default IntroHome
