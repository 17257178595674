import React from "react";

import "bootstrap/dist/css/bootstrap.min.css"
import '../../sass/app.scss';


const Video = ({ data, video }) => {

    return (
        <div className={video ? "iframe-subpage-video" : "video"}>
            <iframe
                src={data.source}
                title={data.title}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                className={video ? "iframe-subpage" : "iframe-video"}
            />
        </div>
    )
}

export default Video